import { profileTypes } from './Constants';

export function changePersonalDataRequest(first_name, last_name) {
	return {
		type: profileTypes.SET_PERSONAL_DATA_REQUEST,
		payload: {
			first_name,
			last_name,
		},
	};
}

export function changePersonalDataSuccess() {
	return {
		type: profileTypes.SET_PERSONAL_DATA_SUCCESS,
		payload: {},
	};
}

export function changePersonalDataError() {
	return {
		type: profileTypes.SET_PERSONAL_DATA_ERROR,
		payload: {},
	};
}

export function toggleChangeEmailModal(status) {
	return {
		type: profileTypes.TOGGLE_CHANGE_EMAIL_MODAL,
		payload: {
			status,
		},
	};
}

export function changeEmailRequest(email, password) {
	return {
		type: profileTypes.SET_NEW_EMAIL,
		payload: {
			email,
			password,
		},
	};
}

export function checkEmailRequest(email, admin) {
	return {
		type: profileTypes.CHECK_EMAIL,
		payload: {
			email,
			admin,
		},
	};
}

export function changeEmailSuccess(token) {
	return {
		type: profileTypes.SET_NEW_EMAIL_SUCCESS,
		payload: {
			token,
		},
	};
}

export function changeEmailError() {
	return {
		type: profileTypes.SET_NEW_EMAIL_ERROR,
		payload: {},
	};
}

export function toggleChangePasswordModal(status) {
	return {
		type: profileTypes.TOGGLE_CHANGE_PASSWORD_MODAL,
		payload: {
			status,
		},
	};
}

export function changePasswordRequest(newPassword, oldPassword) {
	return {
		type: profileTypes.SET_NEW_PASSWORD,
		payload: {
			newPassword,
			oldPassword,
		},
	};
}

export function changePasswordSuccess() {
	return {
		type: profileTypes.SET_NEW_PASSWORD_SUCCESS,
		payload: {},
	};
}

export function changePasswordError() {
	return {
		type: profileTypes.SET_NEW_PASSWORD_ERROR,
		payload: {},
	};
}

import { educationCheckActionTypes } from '@/components/modals/mistakeIntervention/Constants';

const initialState = {
	mistakeInterventionId: null,
	openEducationCheck: false,
	openReviewTopics: false,
};

export default function educationCheckReducer(state = initialState, action) {
	const { type, payload } = action;
	const { mistakeInterventionId, openEducationCheck, openReviewTopics } = payload || {};

	switch (type) {
		case educationCheckActionTypes.TOGGLE_EDUCATION_CHECK_MODAL:
			return {
				...state,
				openEducationCheck,
			};
		case educationCheckActionTypes.TOGGLE_REVIEW_TOPICS_MODAL:
			return {
				...state,
				openReviewTopics,
			};
		case educationCheckActionTypes.SET_MISTAKE_INTERVENTION_ID:
			return {
				...state,
				mistakeInterventionId,
			};
		default:
			return state;
	}
}

export const ADMIN_FRONTEND_ROUTES = {
	LOGIN: '/inloggen',
	SUBJECTS: '/subjects',
	SUBJECT_DETAILS: '/subjects/:subjectId',
	TOPICS: '/topics',
	INSTRUCTION: '/topics/:id/instruction',
	QUESTION_SET: '/question-set/:id',
	QUESTION_SET_QUESTION: '/question-set/:id/question/:questionId',
	QUESTION_SET_QUESTION_TAB: '/question-set/:id/question/:questionId/:tab',
	QUESTION_SET_READING_TEXT: '/question-set/:id/reading-text',
	PREVIEW_EXERCISE: '/question-set/:id/:type',
	PREVIEW_EXERCISE_QUESTION: '/question-set/:id/:type/:questionId',
	MY_PROFILE: '/mijn-profiel',
};

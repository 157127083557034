import { changeGradeModalActionTypes } from '@/components/modals/changeGrade/Constants';

const initialState = {
	open: false,
};

export default function changeGradeModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { open } = payload || {};

	switch (type) {
		case changeGradeModalActionTypes.TOGGLE_CHANGE_GRADE_MODAL:
			return {
				...state,
				open,
			};
		default:
			return state;
	}
}

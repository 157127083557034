export const STICKER_REWARDS = {
	CHOOSE_PROFILE_EMOJI: 'emoji_as_profile_image',
	SKIP_TILE: 'skip_a_tile',
	SHORTER_LEARNING_PATH: 'shorter_learning_path',
	NAME_GENERATOR: 'name_generator',
	CONFETTI_DAY: 'confetti_day',
	COLOR_CHANGER: 'color_changer',
	SEND_MESSAGE_TO_PARENTS: 'send_a_message_to_parents',
	REPLACE_TILE: 'replace_a_tile',
};

export const STICKER_SHAPES = {
	SQUIRCLE: 'squircle',
	HEXAGON: 'hexagon',
	FLAG: 'flag',
	FLOWER: 'flower',
};

export const STICKER_COLORS = {
	orange: ['#FF6B0F', '#FFAF47', '#FFF3DC'],
	yellow: ['#F4AC0F', '#FFCF00', '#FFFCE1'],
	turquoise: ['#0DCFA1', '#52ECC8', '#DBFFF7'],
	lavender: ['#7E5AF7', '#937BFF', '#C5B8FF'],
};

export const TOTAL_STICKERS_FOR_REWARD = 5;

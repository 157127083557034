import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all, delay } from 'redux-saga/effects';
import { playButtonActionTypes } from './Constants';
import * as playButtonActions from './Actions';
import * as notificationActions from '../notification/Actions';

export default function* () {
	yield all([yield takeLatest(playButtonActionTypes.GET_SONG_REQUEST, handleGetSong)]);
}

export function* handleGetSong(action) {
	try {
		const { id, word, lang } = action.payload || {};
		let { data } = yield call(
			processRequest,
			`external_services/speak?lang=${lang}&word=${word}`,
		);
		let hasUrl = data.url;
		while (!hasUrl) {
			const { data: data_ } = yield call(
				processRequest,
				`external_services/speak?lang=${lang}&word=${word}`,
				'GET',
				{},
				false,
				false,
				{},
			);
			yield delay(1000);
			hasUrl = data_.url;
			data = data_;
		}

		yield put(playButtonActions.getSongSuccess(id, data));
	} catch (e) {
		yield put(notificationActions.createNotification(e.response.data.error, null, true, 5000));
		yield put(playButtonActions.removeSong());
	}
}

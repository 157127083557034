import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { clearSubmitErrors, stopSubmit } from 'redux-form';
import i18n from 'i18next';
import { processRequest } from '../../../services/Api';
import { profileTypes } from './Constants';
import { getUserId, getUserData } from './Selectors';
import * as profileActions from './Actions';
import * as notificationActions from '../../../components/notification/Actions';
import * as sharedActions from '../../../shared/Actions';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(profileTypes.SET_PERSONAL_DATA_REQUEST, handleSetPersonalDataRequest),
		yield takeLatest(profileTypes.SET_NEW_PASSWORD, handleChangePasswordRequest),
		yield takeLatest(profileTypes.CHECK_EMAIL, handleCheckEmailRequest),
		yield takeLatest(profileTypes.SET_NEW_EMAIL, handleChangeEmailRequest),
	]);
}

export function* handleSetPersonalDataRequest(action) {
	try {
		const { first_name, last_name } = action.payload;
		const userData = yield select(getUserData);

		yield call(processRequest, `users/${userData.id}`, 'PUT', {
			user: {
				first_name,
				last_name,
			},
		});

		yield put(profileActions.changePersonalDataSuccess());

		yield put(sharedActions.getUserDataRequest());

		yield put(
			notificationActions.createNotification(
				i18n.t('general.saved_changes'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(profileActions.changePersonalDataError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleCheckEmailRequest(action) {
	try {
		const { email, admin } = action.payload;

		yield call(processRequest, `public/user_accounts?email=${email}`);

		yield put(
			stopSubmit(admin ? 'personalInfoForm' : 'changeEmailForm', {
				email: i18n.t('profile_settings.email_exist_notification'),
			}),
		);
	} catch (e) {
		const { admin } = action.payload;
		const formName = admin ? 'personalInfoForm' : 'changeEmailForm';
		if (e.response.status !== 404) {
			yield put(errorHandlerActions.handleError(e, 'notification'));
		}
		yield put(clearSubmitErrors(formName, 'email'));
	}
}

export function* handleChangeEmailRequest(action) {
	try {
		const { email, password } = action.payload;
		const userId = yield select(getUserId);
		const { data } = yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				account: {
					email,
					current_password: password,
				},
			},
		});

		yield put(profileActions.changeEmailSuccess(data.auth_token));

		yield put(sharedActions.getUserDataRequest());

		yield put(
			notificationActions.createNotification(
				i18n.t('profile_settings.email_changed_notification'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(stopSubmit('changeEmailForm', { password: e.response.data.error }));
		yield put(profileActions.changeEmailError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleChangePasswordRequest(action) {
	try {
		const { newPassword, oldPassword } = action.payload;
		const userId = yield select(getUserId);
		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				account: {
					password: newPassword,
					current_password: oldPassword,
				},
			},
		});

		yield put(sharedActions.getUserDataRequest());

		yield put(
			notificationActions.createNotification(
				i18n.t('profile_settings.password_changed_notification'),
				null,
				false,
				3000,
			),
		);

		yield put(profileActions.changePasswordSuccess());
	} catch (e) {
		if (e.response.status === 403) {
			yield put(stopSubmit('changePasswordForm', { oldPassword: e.response.data.error }));
		} else if (e.response.status === 422) {
			yield put(stopSubmit('changePasswordForm', { newPassword: e.response.data.error }));
		}
		yield put(profileActions.changePasswordError());
	}
}

/* eslint-disable import/no-cycle */
import addWysiwygImageModalSaga from './components/wysiwyg/addImage/Sagas';
import adminPlaylistSaga from './pages/quiz/Sagas';
import adminInstructionSaga from './pages/instruction/Sagas';

import profileSagas from './pages/profile/Sagas';
import subjectsSaga from './pages/subjects/Sagas';
import subjectDetailsSaga from './pages/subjectDetails/Sagas';
import topicsSaga from './pages/topics/Sagas';

import manageSubjectSaga from './components/modals/manageSubject/Sagas';
import manageCategorySaga from './components/modals/manageCategory/Sagas';
import manageSubcategorySaga from './components/modals/manageSubcategory/Sagas';
import manageTopicSaga from './components/modals/manageTopic/Sagas';
import managePlaylistSaga from './components/modals/managePlaylist/Sagas';
import manageDifficultySaga from './components/modals/manageDifficulty/Sagas';

const rootAdminSaga = () => [
	addWysiwygImageModalSaga,
	adminPlaylistSaga,
	adminInstructionSaga,

	profileSagas,
	subjectsSaga,
	subjectDetailsSaga,
	topicsSaga,

	manageSubjectSaga,
	manageCategorySaga,
	manageSubcategorySaga,
	manageTopicSaga,
	managePlaylistSaga,
	manageDifficultySaga,
];

export default rootAdminSaga;

/* eslint-disable no-param-reassign */
import i18n from 'i18next';
import Validator from 'validator';
import { MIN_PASSWORD_LENGTH, MIN_SIGN_UP_PASSWORD_LENGTH } from './Constants';

export const validateEmail = (values, errors, field = 'email') => {
	if (!values[field] && field !== 'parent_email')
		errors[field] = i18n.t('sign_up.errors.not_valid_email');
	if (values[field] && !Validator.isEmail(values[field].trim()))
		errors[field] = i18n.t('sign_up.errors.wrong_email_format');

	return errors;
};

function validatePassword(values, errors, minLength = MIN_PASSWORD_LENGTH) {
	if (!values.password) errors.password = true;
	if (values.password && values.password.length < minLength) {
		errors.password = i18n.t('sign_up.errors.password_validation', {
			passwordLength: minLength,
		});
	}
}

function validatePersonalData(values, errors) {
	const { first_name, last_name, child_name } = values;
	if (!first_name) errors.first_name = i18n.t('sign_up.errors.first_name_is_required');
	if (!last_name) errors.last_name = i18n.t('sign_up.errors.last_name_is_required');
	if (!child_name) errors.child_name = i18n.t('sign_up.errors.child_name_is_required');
}

export const signInValidate = (values) => {
	const errors = {};

	if (isSimpleEmail(values.email_or_username)) {
		validateEmail(values, errors, 'email_or_username');
	}
	validatePassword(values, errors);

	return errors;
};

export const forgotPasswordValidate = (values) => {
	const errors = {};

	validateEmail(values, errors);

	return errors;
};

export const resetPasswordValidate = (values) => {
	const errors = {};

	validatePassword(values, errors, MIN_SIGN_UP_PASSWORD_LENGTH);

	return errors;
};

export const changePasswordValidate = (values) => {
	const errors = {};

	if (!values.newPassword) {
		errors.newPassword = i18n.t('sign_up.errors.not_valid_new_password');
	} else if (values.newPassword.length < MIN_SIGN_UP_PASSWORD_LENGTH) {
		errors.newPassword = i18n.t('sign_up.errors.password_validation', {
			passwordLength: MIN_SIGN_UP_PASSWORD_LENGTH,
		});
	}

	if (!values.oldPassword) {
		errors.oldPassword = i18n.t('sign_up.errors.not_valid_old_password');
	}

	return errors;
};

export const changeEmailValidate = (values) => {
	const errors = {};

	validateEmail(values, errors);
	if (!values.email) errors.email = true;
	if (!values.password) errors.password = true;

	return errors;
};

export const personalInfoValidate = (values) => {
	const errors = {};

	validatePersonalData(values, errors);

	if (!values.username) {
		errors.username = i18n.t('sign_up.errors.username_is_required');
	} else if (values.username.split(' ').length > 1) {
		errors.username = i18n.t('sign_up.errors.username_validation');
	}

	return errors;
};

export const savePasswordValidate = (values) => {
	const errors = {};

	validatePassword(values, errors, MIN_SIGN_UP_PASSWORD_LENGTH);

	return errors;
};

export const saveChildNameValidate = (values) => {
	const errors = {};
	const { child_name } = values;

	if (!child_name) errors.child_name = i18n.t('sign_up.errors.child_name_is_required');

	return errors;
};

export const generateInstructionsValidate = (values) => {
	const errors = {};
	const { prompt, example_question_set_id } = values;

	if (!prompt) {
		errors.prompt = true;
	}
	if (!example_question_set_id) {
		errors.example_question_set_id = true;
	}

	return errors;
};

export const generateQuestionSetsValidate = (values) => {
	const errors = {};
	const { prompt, amount } = values;

	if (!prompt) {
		errors.prompt = true;
	}
	if (!amount) {
		errors.amount = true;
	}

	return errors;
};

export const isSimpleEmail = (email_or_username) => email_or_username?.includes('@');

import configData from '../config.json';

export const BASE_API_URL = configData.api_url;
export const WSS_URL = configData.wss_url;
export const BASE_URL = configData.resources_url;
export const FRONTEND_URL = configData.frontend_url;
export const ADMIN_FRONTEND_URL = configData.admin_frontend_url;
export const GTM_CONTAINER_ID = configData.gtm_container_id;
export const GA4_TAG_ID = configData.ga4_tag_id;
export const APP_ENVIRONMENT = configData.environment;
export const TYPO_CHECKER_TOKEN = configData.typo_checker_token;
export const MARKETING_URL = configData.marketing_url;
export const TYPO_CHECKER_API_URL = configData.typo_checker_api_url;
export const AVAILABLE_LANGUAGES = configData.available_languages;
export const BRAND_NAME = configData.brand_name;
export const BASENAME = configData.basename;
export const EXIT_SURVEY_URL = configData.exit_survey_url;
export const BUGSNAG_API_KEY = configData.bugsnag_api_key;
export const REACT_APP_BUILD_NUMBER = configData.react_app_build_number;
export const PROGRESS_FEEDBACK_SURVEY_CODE = configData.progress_feedback_survey_code;
export const QUESTION_FEEDBACK_SURVEY_CODE = configData.question_feedback_survey_code;
export const GENERAL_FEEDBACK_SURVEY_CODE = configData.general_feedback_survey_code;
export const LOGOUT_REDIRECT_URL = `${FRONTEND_URL}/uitloggen`;
export const AUTH_URL = `${FRONTEND_URL}/oauth2/auth`;

export const PLATFORMS = {
	IOS: 'ios',
	ANDROID: 'android',
};

export const IS_ADMIN = import.meta.env.VITE_BUILD_TARGET === 'admin';

export const IS_MOBILE_DEVICE =
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const IS_MOBILE_APP = !!window.cordova;
export const IS_IOS_APP = window.cordova && window.cordova.platformId === PLATFORMS.IOS;
export const IS_ANDROID_APP = window.cordova && window.cordova.platformId === PLATFORMS.ANDROID;

export const DEVICE_TYPE = {
	type: undefined,
	touch: undefined,
};

export const COOKIES = {
	accessToken: 'token',
	coupon: 'coupon',
	deviceToken: 'deviceToken',
	lastCorrectStreak: 'lastCorrectStreak',
	lastGclidTag: 'lastGclidTag',
	lastPageSlug: 'lastPageSlug',
	loggedIn: 'loggedIn',
	renewTime: 'renewTime',
	temporaryToken: 'temporaryToken',
	tokenValidUntil: 'token_valid_until',
	secureSession: '__Secure-session',
};

export const IS_MOBILE_VIEW = window.innerWidth < 768;
export const IS_TABLET_VIEW = window.innerWidth < 1024;
export const IS_DESKTOP_VIEW = window.innerWidth >= 1024;

export const MIN_DAY = 1;
export const MAX_DAY = 31;
export const MIN_YEAR = 1900;
export const MAX_YEAR = new Date().getFullYear();
export const MIN_PASSWORD_LENGTH = 5;
export const MIN_SIGN_UP_PASSWORD_LENGTH = 8;

export const SEARCH_PATH = '/search';

export const UTM_TAGS_TTL_DAYS = 1;

export const IMAGES_EXTENSIONS = [
	'image/png',
	'image/jpeg',
	'image/jpg',
	'image/gif',
	'image/svg+xml',
];

export const FILES_EXTENSIONS = [
	'application/doc',
	'application/docx',
	'application/pdf',
	'application/txt',
	'application/xlsx',
	'application/xls',
	'application/csv',
];

export const MAX_IMAGE_SIZE = 5000000;

export const MAX_FILE_SIZE = 5000000;

export const MAX_ATTACHMENTS = 10;

export const exerciseTypeCodesNext = {
	OPEN_ENDED: 'Question::OpenEnded',
	DICTATE: 'Question::Dictation',
	IN_YOUR_MIND: 'Question::InYourMind',
	MULTIPLE_CHOICE: 'Question::MultipleChoiceSingleAnswer',
	THE_BLANKS: 'Question::FillInTheBlanks',
	OPEN_MULTIPLE: 'Question::OpenQuestionMultipleAnswers',
	DICTATE_MULTIPLE: 'Question::DictationMultipleChoice',
	MULTIPLE_CHOICE_MULTIPLE_ANSWERS: 'Question::MultipleChoiceMultipleAnswers',
};

export const EXERCISE_VIEW_MODES = {
	REGULAR: 'regular',
	PREVIEW: 'preview',
	REPORT: 'report',
};

export const APPLE_STORE_LINK = 'https://apps.apple.com/nl/app/wrts-woordjes-leren/id1191740855';

export const GOOGLE_PLAY_STORE_LINK =
	'https://play.google.com/store/apps/details?id=nl.wrts.mobiel';

export const PUNCTUATION_CHARACTERS = [',', '-', '.', '!', '?', '_', '+', ':'];

export const ACCESS_TOKEN_EXPIRATION_DAYS = 365;

export const DEFAULT_LANGUAGE_LOCALE = 'nl';

export const IS_PRODUCTION_ENVIRONMENT = !['staging', 'localhost'].some((element) =>
	FRONTEND_URL.includes(element),
);

export const IS_LOCAL_ENVIRONMENT = FRONTEND_URL.includes('localhost');

export const LAST_CORRECT_STREAK_DAYS = 2;

export const TYPEFORM_SCRIPT_URL = '//embed.typeform.com/next/embed.js';

export const FRONTEND_ROUTES = {
	ACTIVITY: '/activiteit',
	DAILY_GOAL: '/doel-gehaald',
	EXERCISE: '/doel/:section/:id',
	EXERCISE_REPORT: '/activiteit/:id/:questionId',
	GOAL_SETTINGS: '/doel-instellen',
	LOGIN: '/inloggen',
	NEW_SCHOOL_YEAR: '/groep-aanpassen',
	ONBOARDING_CHOOSE_GRADE: '/groep-kiezen',
	ONBOARDING_CHOOSE_REFERENCE: '/hoe-ken-je-ons',
	ONBOARDING_SAVE_CHILD_NAME: '/naam-aanmaken',
	ONBOARDING_SAVE_PASSWORD: '/wachtwoord-aanmaken',
	PROGRESS: '/voortgang',
	PROGRESS_DETAILS: '/voortgang/:id/:slug',
	REPORT: '/activiteit/:id',
	SAVE_CHILD_NAME: '/naam-toevoegen',
	START_SCREEN: '/doel-instellen-nieuw',
	STICKERS_ONBOARDING: '/uitleg-stickers',
};

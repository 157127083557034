import { put, call, takeLatest, all, select, delay } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { progressActionTypes } from './Constants';
import { getProgressSubjects } from './Selectors';
import * as progressActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			progressActionTypes.GET_PROGRESS_OVERVIEW_REQUEST,
			handleProgressOverviewRequest,
		),
		yield takeLatest(
			progressActionTypes.GET_PROGRESS_DETAILS_REQUEST,
			handleProgressDetailsRequest,
		),
	]);
}

export function* handleProgressOverviewRequest() {
	try {
		const { data } = yield call(processRequest, 'progress/overview', 'GET');

		yield put(progressActions.getProgressOverviewSuccess(data.activity, data.subjects));
	} catch (e) {
		yield put(progressActions.getProgressOverviewError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleProgressDetailsRequest({ payload }) {
	try {
		const { subcategoryId } = payload;

		let subjects = yield select(getProgressSubjects);

		yield delay(200);

		if (!subjects?.length) {
			const { data } = yield call(processRequest, 'progress/overview', 'GET');
			subjects = data.subjects;
		}

		if (subjects?.length) {
			for (const subject of subjects) {
				const subcategories = ['missing_practice', 'previous_progress', 'progress'];
				let isFound = false;

				for (const category of subcategories) {
					if (isFound) break;
					if (subject.subcategories?.[category]?.length) {
						for (const subcategory of subject.subcategories[category]) {
							if (subcategory.id === subcategoryId) {
								yield put(progressActions.getProgressDetailsSuccess(subcategory));
								isFound = true;
								break;
							}
						}
					}
				}
			}
		}
	} catch (e) {
		yield put(progressActions.getProgressDetailsError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}

import keyMirror from 'keymirror';

export const topicsActionTypes = keyMirror({
	GET_ADMIN_TOPICS_REQUEST: null,
	GET_ADMIN_TOPICS_SUCCESS: null,
	GET_ADMIN_TOPICS_ERROR: null,

	SET_ADMIN_TOPIC_OPEN_STATE: null,
	SET_ADMIN_ALL_TOPICS_OPEN_STATE: null,

	CHANGE_ADMIN_TOPIC_POSITION_REQUEST: null,
	CHANGE_ADMIN_TOPIC_POSITION_SUCCESS: null,
	CHANGE_ADMIN_TOPIC_POSITION_ERROR: null,

	DELETE_ADMIN_TOPIC_REQUEST: null,
	DELETE_ADMIN_TOPIC_SUCCESS: null,
	DELETE_ADMIN_TOPIC_ERROR: null,

	GET_TOPIC_PLAYLISTS_REQUEST: null,
	GET_TOPIC_PLAYLISTS_SUCCESS: null,
	GET_TOPIC_PLAYLISTS_ERROR: null,

	DELETE_PLAYLIST_REQUEST: null,
	DELETE_PLAYLIST_SUCCESS: null,
	DELETE_PLAYLIST_ERROR: null,

	DUPLICATE_PLAYLIST_REQUEST: null,
	DUPLICATE_PLAYLIST_SUCCESS: null,
	DUPLICATE_PLAYLIST_ERROR: null,

	SET_ADMIN_APPLIED_FILTERS: null,

	CLEAN_ADMIN_TOPICS_STATE: null,

	GENERATE_INSTRUCTIONS_REQUEST: null,
	GENERATE_INSTRUCTIONS_SUCCESS: null,
	GENERATE_INSTRUCTIONS_ERROR: null,

	GENERATE_QUESTION_SETS_REQUEST: null,
	GENERATE_QUESTION_SETS_SUCCESS: null,
	GENERATE_QUESTION_SETS_ERROR: null,
});

export const GENERATE_INSTRUCTIONS_FORM = 'GenerateInstructionsForm';

export const GENERATE_QUESTION_SETS_FORM = 'GenerateQuestionSetsForm';

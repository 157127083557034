import { signInActionTypes } from './Constants';

export function loginRequest(email_or_username, password, path) {
	return {
		type: signInActionTypes.LOGIN_REQUEST,
		payload: {
			email_or_username,
			password,
			path,
		},
	};
}

export function loginSuccess(token) {
	return {
		type: signInActionTypes.LOGIN_SUCCESS,
		payload: {
			token,
		},
	};
}

export function loginError() {
	return {
		type: signInActionTypes.LOGIN_ERROR,
	};
}

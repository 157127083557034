import { activityActionTypes } from '@/pages/activity/Constants';

export function getReportActivityRequest(date, weeks = 3, callback) {
	return {
		type: activityActionTypes.GET_REPORT_ACTIVITY_REQUEST,
		payload: {
			date,
			weeks,
			callback,
		},
	};
}

export function getReportActivitySuccess(activity, startedDate) {
	return {
		type: activityActionTypes.GET_REPORT_ACTIVITY_SUCCESS,
		payload: {
			activity,
			startedDate,
		},
	};
}

export function getReportActivityError(error) {
	return {
		type: activityActionTypes.GET_REPORT_ACTIVITY_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function getPreviousReportActivityRequest(date) {
	return {
		type: activityActionTypes.GET_PREVIOUS_REPORT_ACTIVITY_REQUEST,
		payload: {
			date,
		},
	};
}

export function getNextReportActivityRequest(date, callback) {
	return {
		type: activityActionTypes.GET_NEXT_REPORT_ACTIVITY_REQUEST,
		payload: {
			date,
			callback,
		},
	};
}

export function getPlayedDaysRequest(date) {
	return {
		type: activityActionTypes.GET_PLAYED_DAYS_REQUEST,
		payload: {
			date,
		},
	};
}

export function getPlayedDaysSuccess(playedDays) {
	return {
		type: activityActionTypes.GET_PLAYED_DAYS_SUCCESS,
		payload: {
			playedDays,
		},
	};
}

export function getPlayedDaysError(error) {
	return {
		type: activityActionTypes.GET_PLAYED_DAYS_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function setActiveDate(activeDate) {
	return {
		type: activityActionTypes.SET_ACTIVE_DATE,
		payload: {
			activeDate,
		},
	};
}

export function setPreviousDate(previousDate) {
	return {
		type: activityActionTypes.SET_PREVIOUS_DATE,
		payload: {
			previousDate,
		},
	};
}

export function setNextDate(nextDate) {
	return {
		type: activityActionTypes.SET_NEXT_DATE,
		payload: {
			nextDate,
		},
	};
}

import keyMirror from 'keymirror';

export const sidebarActionTypes = keyMirror({
	SET_EXPANDED_MENU: null,
	SET_SETTINGS_MENU: null,
});

export const sectionIcons = {
	subjects: 'icon-grid',
	topics: 'icon-bookshelf',
};

import { call, all, takeLatest, takeEvery, put, delay } from 'redux-saga/effects';
import { topicsActionTypes } from './Constants';
import { processRequest } from '../../../services/Api';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';
import * as topicsActions from './Actions';

export default function* () {
	yield all([
		yield takeLatest(topicsActionTypes.GET_ADMIN_TOPICS_REQUEST, handleTopicsRequest),
		yield takeLatest(
			topicsActionTypes.CHANGE_ADMIN_TOPIC_POSITION_REQUEST,
			handleChangeTopicPositionRequest,
		),
		yield takeLatest(topicsActionTypes.DELETE_ADMIN_TOPIC_REQUEST, deleteTopicRequest),
		yield takeEvery(
			topicsActionTypes.GET_TOPIC_PLAYLISTS_REQUEST,
			handleGetTopicPlaylistsRequest,
		),
		yield takeLatest(topicsActionTypes.DELETE_PLAYLIST_REQUEST, handleDeletePlaylistRequest),
		yield takeLatest(
			topicsActionTypes.DUPLICATE_PLAYLIST_REQUEST,
			handleDuplicatePlaylistRequest,
		),
		yield takeLatest(
			topicsActionTypes.GENERATE_INSTRUCTIONS_REQUEST,
			handleGenerateInstructionsRequest,
		),
		yield takeLatest(
			topicsActionTypes.GENERATE_QUESTION_SETS_REQUEST,
			handleGenerateQuestionSetsRequest,
		),
	]);
}

export function* handleTopicsRequest({ payload }) {
	try {
		const { appliedFilters } = payload || {};
		const subjectId = appliedFilters.subject_id;
		const bufAppliedFilters = { ...appliedFilters };
		delete bufAppliedFilters.subject_id;

		const { data } = yield call(
			processRequest,
			`admin/subjects/${subjectId}/topics`,
			'GET',
			bufAppliedFilters,
		);
		yield put(topicsActions.getTopicsSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(topicsActions.getTopicsError());
	}
}

export function* handleChangeTopicPositionRequest({ payload }) {
	try {
		const { id, position, callback } = payload || {};

		yield call(processRequest, `admin/topics/${id}`, 'PUT', { topic: { position } });
		callback && callback();

		yield put(topicsActions.changeTopicPositionSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(topicsActions.changeTopicPositionError());
	}
}

export function* deleteTopicRequest({ payload }) {
	try {
		const { id, callback } = payload || {};

		yield call(processRequest, `admin/topics/${id}`, 'DELETE');
		callback && callback();

		yield put(topicsActions.deleteTopicSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(topicsActions.deleteTopicError());
	}
}

export function* handleGetTopicPlaylistsRequest({ payload }) {
	try {
		const { topicId, searchTerms } = payload || {};
		const query = searchTerms ? { search_terms: searchTerms } : {};

		const { data } = yield call(
			processRequest,
			`admin/topics/${topicId}/playlist_sections`,
			'GET',
			query,
		);

		yield put(topicsActions.getTopicPlaylistsSuccess({ topicId, playlists: data.results }));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(topicsActions.getTopicPlaylistsError());
	}
}

export function* handleDeletePlaylistRequest({ payload }) {
	try {
		const { playlistId, callback } = payload || {};

		yield call(processRequest, `admin/playlist_sections/${playlistId}`, 'DELETE');
		callback && callback();

		yield put(topicsActions.deletePlaylistSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(topicsActions.deletePlaylistError());
	}
}

export function* handleDuplicatePlaylistRequest({ payload }) {
	try {
		const { playlistId, callback } = payload || {};

		yield call(processRequest, `admin/playlist_sections/${playlistId}/duplicate`, 'POST');
		callback && callback();

		yield put(topicsActions.duplicatePlaylistSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(topicsActions.duplicatePlaylistError());
	}
}

export function* handleGenerateInstructionsRequest({ payload }) {
	try {
		const { topicId, data, callback } = payload || {};

		yield call(processRequest, `admin/topics/${topicId}/question_instructions`, 'POST', data);

		yield delay(5000);

		yield put(topicsActions.generateInstructionsSuccess());

		callback && callback();
	} catch (e) {
		const { data, status } = e.response || {};
		let customNotification = null;
		if (data.error) {
			customNotification = { code: status, message: data.error };
		}
		yield put(errorHandlerActions.handleError(e, 'notification', customNotification));
		yield put(topicsActions.generateInstructionsError());
	}
}

export function* handleGenerateQuestionSetsRequest({ payload }) {
	try {
		const { data, callback } = payload || {};

		yield call(processRequest, `admin/playlist_sections/generate`, 'POST', data);

		yield delay(5000);

		yield put(topicsActions.generateQuestionSetsSuccess());

		callback && callback();
	} catch (e) {
		const { data, status } = e.response || {};
		let customNotification = null;
		if (data.error) {
			customNotification = { code: status, message: data.error };
		}
		yield put(errorHandlerActions.handleError(e, 'notification', customNotification));
		yield put(topicsActions.generateQuestionSetsError());
	}
}

import { ADMIN_FRONTEND_ROUTES } from './Constants';

export const getSubjectDetailsPath = (subjectId) => {
	return ADMIN_FRONTEND_ROUTES.SUBJECT_DETAILS.replace(':subjectId', subjectId);
};

export const getInstructionPath = (topicId) => {
	return ADMIN_FRONTEND_ROUTES.INSTRUCTION.replace(':id', topicId);
};

export const getQuestionSetPath = (id) => {
	return ADMIN_FRONTEND_ROUTES.QUESTION_SET.replace(':id', id);
};

export const getQuestionSetQuestionPath = (id, questionId) => {
	return ADMIN_FRONTEND_ROUTES.QUESTION_SET.replace(':id', id).replace(':questionId', questionId);
};

export const getQuestionSetQuestionTabPath = (id, questionId, tab) => {
	return ADMIN_FRONTEND_ROUTES.QUESTION_SET_QUESTION_TAB.replace(':id', id)
		.replace(':questionId', questionId)
		.replace(':tab', tab);
};

export const getQuestionSetReadingTextPath = (id) => {
	return ADMIN_FRONTEND_ROUTES.QUESTION_SET_READING_TEXT.replace(':id', id);
};

export const getPreviewExercisePath = (id, type) => {
	return ADMIN_FRONTEND_ROUTES.PREVIEW_EXERCISE.replace(':id', id).replace(':type', type);
};

export const getPreviewExerciseQuestionPath = (id, type, questionId) => {
	return ADMIN_FRONTEND_ROUTES.PREVIEW_EXERCISE_QUESTION.replace(':id', id)
		.replace(':type', type)
		.replace(':questionId', questionId);
};

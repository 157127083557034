/* eslint-disable import/no-cycle */
import { fork } from 'redux-saga/effects';
import rootAdminSaga from '@/admin/rootSaga';
import rootUserSaga from '@/rootSaga';

export default function* rootSaga() {
	const sagas = [...rootUserSaga(), ...rootAdminSaga()];
	for (const saga of sagas) {
		yield fork(saga);
	}
}

import keyMirror from 'keymirror';

export const MOMENT_DATE_FORMAT = 'DD MMMM YYYY';

export const sharedTypes = keyMirror({
	GET_USER_DATA_REQUEST: null,
	GET_USER_DATA_SUCCESS: null,
	GET_USER_DATA_ERROR: null,

	LOGOUT: null,

	UPDATE_USER_DATA_REQUEST: null,
	UPDATE_USER_DATA_SUCCESS: null,
	UPDATE_USER_DATA_ERROR: null,

	CLEAR_USER_DATA: null,

	SEND_EVENT_REQUEST: null,
	SEND_EVENT_SUCCESS: null,
	SEND_EVENT_ERROR: null,

	SET_TRANSLATIONS_INSTANCE: null,

	SET_BACKGROUND_COLOR: null,
	SET_BACKGROUND_PATTERN: null,

	TOGGLE_MOBILE_FOOTER_MENU: null,

	HANDLE_USER_EXPERIMENT: null,
	HANDLE_USER_EXPERIMENT_SUCCESS: null,

	TOGGLE_GENERAL_FEEDBACK_FORM: null,
});

export const EVENTS_TYPES = {
	UPGRADE_MESSAGE_SEEN: 'upgrade_message_seen',
	MANAGE_PACKAGE_BUTTON_CLICKED: 'manage_package_button_clicked',
	USP_SEEN: 'usp_seen',
	SWITCH_MEMBERSHIP_OPTIONS_VIEWED: 'switch_membership_options_viewed',

	// Goal Settings
	GOAL_SETTINGS_DURATION_SELECTED: 'goalsetting_duration_selected',
	GOAL_SETTINGS_SUBJECTS_SELECTED: 'goalsetting_subjects_selected',
	GOAL_SETTINGS_LANG_VISITED: 'goalsetting_lang_visited',
	GOAL_SETTINGS_MATH_VISITED: 'goalsetting_math_visited',
	GOAL_SETTINGS_EXAMS_VISITED: 'goalsetting_exams_visited',

	// Learning Path
	LEARNING_PATH_ADJUST_BUTTON: 'learningpath_adjust_button',
	LEARNING_PATH_DAY_GOAL_STARTED: 'learningpath_daygoal_started',
	LEARNING_PATH_WELCOME_VIDEO: 'learningpath_welcomevideo',
	LEARNING_PATH_NEW_DIFFICULTY_VIDEO: 'learningpath_newdifficulty_video',

	// Error Page
	ERROR_BROKEN_SCREEN: 'error_broken_screen',

	// Report
	REVIEW_WATCHED_PLAYED_QUESTION_SETS: 'review_watched_played_question_sets',
	REVIEW_PICKED_DATE_IN_THE_PAST: 'review_picked_date_in_the_past',
	REVIEW_VIEWED_QUESTION_DETAIL: 'review_viewed_question_detail',

	QUESTION_ANSWERED_FAST: 'question_answered_fast',
	PROFILE_SWITCH_GRADE: 'profile_switch_grade',
	PROFILE_SWITCH_GRADE_NEWSCHOOLYEAR: 'profile_switch_grade_newschoolyear',

	// Instructions
	INSTRUCTION_VIEWED: 'instruction_viewed',
	INSTRUCTION_AUDIO: 'instruction_audio',
	INSTRUCTION_AUDIO_ONBOARDING: 'instruction_audio_onboarding',
	QUESTION_INSTRUCTION_VIEWED: 'question_instruction_viewed',
	QUESTION_INSTRUCTION_FEEDBACK: 'question_instruction_feedback',

	// Search
	SEARCH_QUERY: 'search_query',
	SEARCH_RESULT_CLICKED: 'search_result_clicked',
	SEARCH_OVERVIEW: 'overview',

	// Daily goal screen
	DAYGOAL_QUESTION_SCREEN_SHOWN: 'daygoal_endscreen_questionscreen_shown',
	DAYGOAL_QUESTION_SENT: 'daygoal_endscreen_question_sent',
	DAYGOAL_FINAL_SCREEN_SHOWN: 'daygoal_endscreen_finalscreen',
	DAYGOAL_SCREEN_CLOSED: 'daygoal_endscreen_closed',
	WEEKGOAL_POPPED_BALLOONS: 'weekgoal_popped_balloons',

	// Second chance
	QUESTION_ANSWERED_SECOND_CHANCE: 'question_answered_secondchance',

	// Reading comprehension
	QUESTION_READING_COMPLETED: 'question_reading_completed',

	// Progress
	PROGRESS_OVERVIEW_VIEWED: 'progress_overview_viewed',
	PROGRESS_DETAIL_VIEWED: 'progress_detail_viewed',
	GRAPH_VIEW_SWITCHED: 'graph_view_switched',
	GRAPH_INTERACTION: 'graph_interaction',
};

export const SCREEN_SIZES = {
	MOBILE: 768,
	TABLET: 1024,
	DESKTOP: 1280,
};

export const getAdminOptionIcon = (item) => {
	return item.id === 'answered_by_tutor'
		? 'icon-chat-answered'
		: item.id === 'approved'
		? 'icon-check'
		: item.id === 'flagged'
		? 'icon-flag'
		: item.id === 'needs_quick_answer'
		? 'icon-chat'
		: item.id === 'to_check'
		? 'icon-search'
		: item.id === 'highlighted'
		? 'icon-verified'
		: 'icon-trash-can';
};

export const SHOW_HOTJAR_FEEDBACKS = false;

export const MIN_DESKTOP_WIDTH = 1024;

export const DEFAULT_BACKGROUND_COLOR = '#f6f3ef';

export const WHITE_BACKGROUND_COLOR = '#ffffff';

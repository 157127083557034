import { educationCheckActionTypes } from '@/components/modals/mistakeIntervention/Constants';

export function toggleEducationCheckModal(open) {
	return {
		type: educationCheckActionTypes.TOGGLE_EDUCATION_CHECK_MODAL,
		payload: { openEducationCheck: open },
	};
}

export function toggleReviewTopicsModal(open) {
	return {
		type: educationCheckActionTypes.TOGGLE_REVIEW_TOPICS_MODAL,
		payload: { openReviewTopics: open },
	};
}

export function setMistakeInterventionId(mistakeInterventionId) {
	return {
		type: educationCheckActionTypes.SET_MISTAKE_INTERVENTION_ID,
		payload: { mistakeInterventionId },
	};
}

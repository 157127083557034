import keyMirror from 'keymirror';

export const adminPlaylistActionTypes = keyMirror({
	GET_PLAYLIST_REQUEST: null,
	GET_PLAYLIST_SUCCESS: null,
	GET_PLAYLIST_ERROR: null,

	MANAGE_PLAYLIST_REQUEST: null,
	MANAGE_PLAYLIST_SUCCESS: null,
	MANAGE_PLAYLIST_ERROR: null,

	SET_QUESTIONS_LIST: null,

	GET_QUESTION_REQUEST: null,
	GET_QUESTION_SUCCESS: null,
	GET_QUESTION_ERROR: null,
	CREATE_QUESTION_REQUEST: null,
	CREATE_QUESTION_SUCCESS: null,
	CREATE_QUESTION_ERROR: null,
	UPDATE_QUESTION_REQUEST: null,
	UPDATE_QUESTION_SUCCESS: null,
	UPDATE_QUESTION_ERROR: null,
	MANAGE_QUESTION_REQUEST: null,
	MANAGE_QUESTION_SUCCESS: null,
	MANAGE_QUESTION_ERROR: null,
	CHANGE_QUESTION_POSITION: null,

	PREVIEW_PLAYLIST_REQUEST: null,
	PREVIEW_PLAYLIST_SUCCESS: null,
	PREVIEW_PLAYLIST_ERROR: null,

	PUBLISH_PLAYLIST_REQUEST: null,
	PUBLISH_PLAYLIST_SUCCESS: null,
	PUBLISH_PLAYLIST_ERROR: null,

	REVERT_TEXT_REQUEST: null,
	REVERT_TEXT_SUCCESS: null,
	REVERT_TEXT_ERROR: null,

	SELECT_PLAYLIST_QUESTION: null,

	SET_UPDATE_TIME: null,

	CLEAR_PLAYLIST_STATE: null,

	CREATE_QUESTION_INSTRUCTION_REQUEST: null,
	CREATE_QUESTION_INSTRUCTION_SUCCESS: null,
	CREATE_QUESTION_INSTRUCTION_ERROR: null,

	UPDATE_QUESTION_INSTRUCTION_REQUEST: null,
	UPDATE_QUESTION_INSTRUCTION_SUCCESS: null,
	UPDATE_QUESTION_INSTRUCTION_ERROR: null,

	DELETE_QUESTION_INSTRUCTION_REQUEST: null,
	DELETE_QUESTION_INSTRUCTION_SUCCESS: null,
	DELETE_QUESTION_INSTRUCTION_ERROR: null,

	TOGGLE_INSTRUCTION_AUDIO_MODAL: null,

	GENERATE_INSTRUCTION_AUDIO_REQUEST: null,
	GENERATE_INSTRUCTION_AUDIO_SUCCESS: null,
	GENERATE_INSTRUCTION_AUDIO_ERROR: null,

	DELETE_INSTRUCTION_AUDIO_REQUEST: null,
	DELETE_INSTRUCTION_AUDIO_SUCCESS: null,
	DELETE_INSTRUCTION_AUDIO_ERROR: null,
});

export const removeIdsFromList = (data) => {
	return data.map((item) => {
		const formattedItem = { ...item };
		delete formattedItem.id;
		return formattedItem;
	});
};

export const removeIdsFromObject = (data) => {
	const formattedData = { ...data };
	if (formattedData.answer_variations && formattedData.answer_variations.length) {
		formattedData.answer_variations = removeIdsFromList(formattedData.answer_variations);
	}
	delete formattedData.id;
	return formattedData;
};

export const formatDataForCompare = (data) => {
	const formattedData = { ...data };
	formattedData.answer_variations_attributes = formattedData.answer_variations;
	delete formattedData.answer_variations;
	delete formattedData.question_part;
	delete formattedData.id;
	formattedData.answer_variations_attributes = formattedData.answer_variations_attributes.map(
		(el) => ({ answer_text: el.answer_text }),
	);
	return formattedData;
};

export const UPDATE_DELAY = 1000;

export const SECTION_TYPES = {
	GENERAL: 'general',
	READING: 'reading',
};

export const DIFFICULTIES = [
	{ name: 'Easy', value: 'easy' },
	{ name: 'Medium', value: 'medium' },
	{ name: 'Hard', value: 'hard' },
];

export const QUESTION_ICONS = {
	'icon-format-list-bulletedsvg': 'icon-list-bulleted',
	'icon-format-list-numbered': 'icon-list-numbered',
	'icon-fill': 'icon-writing',
};

export const STOP_AUDIO_PLAYING_EVENT = 'stopAudioPlaying';

export const getQuestionIcon = (iconName) => {
	return QUESTION_ICONS[iconName] ? QUESTION_ICONS[iconName] : iconName;
};

export const getPlaylistUrl = (id, questionId, tab) => {
	if (questionId === SECTION_TYPES.READING) {
		return `/question-set/${id}/reading-text`;
	}
	if (questionId) {
		return `/question-set/${id}/question/${questionId}${tab ? `/${tab}` : ''}`;
	}
	return `/question-set/${id}`;
};

import keyMirror from 'keymirror';
import { FRONTEND_URL } from '@/services/Constants';

export const changeGradeModalActionTypes = keyMirror({
	TOGGLE_CHANGE_GRADE_MODAL: null,
});

export const getChangeGradeLink = (childId) => {
	return `${FRONTEND_URL}/accounts?redirect_url=L2NoaWxkLzEyMzQvcHJvZmlsZQ==&redirect_qs=dGFiP3Byb2ZpbGU=&target_user=${childId}`;
};

import { setGTMData } from '../../services/Helper';
import { signInActionTypes } from './Constants';
import { removeTemporaryAccessToken, setAccessToken } from '../../services/AccessToken';

const initialState = {
	loading: false,
	token: '',
	userId: null,
};

export default function signInReducer(state = initialState, action) {
	const { type, payload } = action;
	const { token } = payload || {};

	switch (type) {
		case signInActionTypes.LOGIN_REQUEST:
			return {
				...state,
				loading: true,
			};

		case signInActionTypes.LOGIN_SUCCESS:
			setAccessToken(token);
			removeTemporaryAccessToken();

			setGTMData({
				event: 'successfulLogin',
			});

			return {
				...state,
				token: '',
				userId: null,
				loading: false,
				socialLoading: false,
			};

		case signInActionTypes.LOGIN_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}

import { exerciseActionTypes } from '@/pages/exercise/Constants';

export function getQuestionExplanation(questionId, exerciseId) {
	return {
		type: exerciseActionTypes.GET_QUESTION_EXPLANATION,
		payload: {
			questionId,
			exerciseId,
		},
	};
}

export function getQuestionExplanationSuccess(data) {
	return {
		type: exerciseActionTypes.GET_QUESTION_EXPLANATION_SUCCESS,
		payload: {
			data,
		},
	};
}

export function clearExplanationState() {
	return {
		type: exerciseActionTypes.CLEAR_EXPLANATION,
	};
}

export function clearState() {
	return {
		type: exerciseActionTypes.CLEAR_STATE,
	};
}

export function setExerciseScore(correctCount, incorrectCount, questionsLeftCount) {
	return {
		type: exerciseActionTypes.SET_EXERCISE_SCORE,
		payload: {
			correctCount,
			incorrectCount,
			questionsLeftCount,
		},
	};
}

export function getExerciseRequest(data) {
	return {
		type: exerciseActionTypes.GET_EXERCISE_REQUEST,
		payload: data,
	};
}

export function getExerciseSuccess(data) {
	return {
		type: exerciseActionTypes.GET_EXERCISE_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getExerciseError() {
	return {
		type: exerciseActionTypes.GET_EXERCISE_ERROR,
	};
}

export function getExerciseReportRequest(data) {
	return {
		type: exerciseActionTypes.GET_EXERCISE_REPORT_REQUEST,
		payload: data,
	};
}

export function getExerciseReportSuccess(data) {
	return {
		type: exerciseActionTypes.GET_EXERCISE_REPORT_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getExerciseReportError() {
	return {
		type: exerciseActionTypes.GET_EXERCISE_REPORT_ERROR,
	};
}

export function sendAnswerRequest(playlistId, data, lastQuestion, callback) {
	return {
		type: exerciseActionTypes.SEND_QUESTION_ANSWER_REQUEST,
		payload: {
			playlistId,
			data,
			lastQuestion,
			callback,
		},
	};
}

export function sendAnswerSuccess() {
	return {
		type: exerciseActionTypes.SEND_QUESTION_ANSWER_SUCCESS,
	};
}

export function sendAnswerError() {
	return {
		type: exerciseActionTypes.SEND_QUESTION_ANSWER_ERROR,
	};
}

export function handleNextExerciseTypes(exerciseType) {
	return {
		type: exerciseActionTypes.HANDLE_NEXT_EXERCISE_TYPES,
		payload: {
			exerciseType,
		},
	};
}

export function toggleTopicInstruction(showTopicInstruction) {
	return {
		type: exerciseActionTypes.TOGGLE_TOPIC_INSTRUCTION,
		payload: {
			showTopicInstruction,
		},
	};
}

export function toggleQuestionInstruction(showQuestionInstruction) {
	return {
		type: exerciseActionTypes.TOGGLE_QUESTION_INSTRUCTION,
		payload: {
			showQuestionInstruction,
		},
	};
}

export function sendTopicInstructionFeedback(isUseful, instructionId, questionId) {
	return {
		type: exerciseActionTypes.SEND_TOPIC_INSTRUCTION_FEEDBACK,
		payload: {
			isUseful,
			instructionId,
			questionId,
		},
	};
}

export function sendQuestionInstructionFeedback(feedback, questionId) {
	return {
		type: exerciseActionTypes.SEND_QUESTION_INSTRUCTION_FEEDBACK,
		payload: {
			feedback,
			questionId,
		},
	};
}

export function storeUserQuestionTime(answerId, playtimeInSeconds) {
	return {
		type: exerciseActionTypes.STORE_USER_QUESTION_TIME,
		payload: {
			answer_id: answerId,
			playtime_in_seconds: playtimeInSeconds,
		},
	};
}

export function setSelectedItems(data) {
	return {
		type: exerciseActionTypes.SET_SELECTED_ITEMS,
		payload: {
			data,
		},
	};
}

export function setCurrentQuestion(currentQuestion) {
	return {
		type: exerciseActionTypes.SET_CURRENT_QUESTION,
		payload: {
			currentQuestion,
		},
	};
}

export function toggleInstructionAudio(audioOn) {
	return {
		type: exerciseActionTypes.TOGGLE_INSTRUCTION_AUDIO,
		payload: {
			audioOn,
		},
	};
}

export function saveAudioPreferencesRequest(audioOn, audioOnboarded) {
	return {
		type: exerciseActionTypes.SAVE_AUDIO_PREFERENCES_REQUEST,
		payload: {
			audioOn,
			audioOnboarded,
		},
	};
}

export function saveAudioPreferencesSuccess() {
	return {
		type: exerciseActionTypes.SAVE_AUDIO_PREFERENCES_SUCCESS,
	};
}

export function saveAudioPreferencesError() {
	return {
		type: exerciseActionTypes.SAVE_AUDIO_PREFERENCES_ERROR,
	};
}

export function setViewMode(viewMode) {
	return {
		type: exerciseActionTypes.SET_VIEW_MODE,
		payload: {
			viewMode,
		},
	};
}

export function toggleReadingMode(isReadingMode) {
	return {
		type: exerciseActionTypes.TOGGLE_READING_MODE,
		payload: {
			isReadingMode,
		},
	};
}

export function toggleReadingTitle(showReadingTitle) {
	return {
		type: exerciseActionTypes.TOGGLE_READING_TITLE,
		payload: {
			showReadingTitle,
		},
	};
}

export function togglePulsatingHighlight(showPulsatingHighlight) {
	return {
		type: exerciseActionTypes.TOGGLE_PULSATING_HIGHLIGHT,
		payload: {
			showPulsatingHighlight,
		},
	};
}

export function setReadingProgress(readingProgress) {
	return {
		type: exerciseActionTypes.SET_READING_PROGRESS,
		payload: {
			readingProgress,
		},
	};
}

export function setReadingTextSize(readingTextSize) {
	return {
		type: exerciseActionTypes.SET_READING_TEXT_SIZE,
		payload: {
			readingTextSize,
		},
	};
}

export function setShowInstructionHint(showInstructionHint) {
	return {
		type: exerciseActionTypes.SET_SHOW_INSTRUCTION_HINT,
		payload: {
			showInstructionHint,
		},
	};
}

export function setInstructionAudioOn(instructionAudioOn) {
	return {
		type: exerciseActionTypes.SET_INSTRUCTION_AUDIO_ON,
		payload: {
			instructionAudioOn,
		},
	};
}

export function setCorrectStreak(correctStreak) {
	return {
		type: exerciseActionTypes.SET_CORRECT_STREAK,
		payload: {
			correctStreak,
		},
	};
}

export function setEndscreen(endscreen) {
	return {
		type: exerciseActionTypes.SET_ENDSCREEN,
		payload: {
			endscreen,
		},
	};
}

export function setLevelUp(levelUp) {
	return {
		type: exerciseActionTypes.SET_LEVEL_UP,
		payload: {
			levelUp,
		},
	};
}

export function finishLearningPathSectionRequest(playlistId) {
	return {
		type: exerciseActionTypes.FINISH_LEARNING_PATH_SECTION_REQUEST,
		payload: {
			playlistId,
		},
	};
}

export function finishLearningPathSectionSuccess() {
	return {
		type: exerciseActionTypes.FINISH_LEARNING_PATH_SECTION_SUCCESS,
	};
}

export function finishLearningPathSectionError() {
	return {
		type: exerciseActionTypes.FINISH_LEARNING_PATH_SECTION_ERROR,
	};
}

export function setMistakeIntervention(mistakeInterventionId) {
	return {
		type: exerciseActionTypes.SET_MISTAKE_INTERVENTION,
		payload: {
			mistakeInterventionId,
		},
	};
}

export function sendMistakeInterventionRequest(playlistId, mistakeInterventionId) {
	return {
		type: exerciseActionTypes.SEND_MISTAKE_INTERVENTION_REQUEST,
		payload: {
			playlistId,
			mistakeInterventionId,
		},
	};
}

export function sendMistakeInterventionSuccess() {
	return {
		type: exerciseActionTypes.SEND_MISTAKE_INTERVENTION_SUCCESS,
	};
}

export function sendMistakeInterventionError() {
	return {
		type: exerciseActionTypes.SEND_MISTAKE_INTERVENTION_ERROR,
	};
}

import { put, call, takeLatest, all } from 'redux-saga/effects';
import { processRequest } from '@/services/Api';
import { dailyReportActionTypes } from '@/components/dailyReport/Constants';
import * as dailyReportActions from '@/components/dailyReport/Actions';
import * as errorHandlerActions from '@/components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(dailyReportActionTypes.GET_DAILY_REPORT_REQUEST, handleDailyReportRequest),
	]);
}

export function* handleDailyReportRequest({ payload }) {
	const { date } = payload || {};

	try {
		const { data: dailyReport } = yield call(
			processRequest,
			`users/daily_report`,
			'GET',
			date && { date },
		);

		// dailyReport.user_playlist_sections[0].is_intervened_section = true;
		// dailyReport.user_playlist_sections[1].is_intervention_section = true;

		yield put(dailyReportActions.getDailyReportSuccess(dailyReport));
	} catch (e) {
		yield put(dailyReportActions.getDailyReportError());
		yield put(errorHandlerActions.handleError(e));
	}
}
import keyMirror from 'keymirror';

export const exerciseActionTypes = keyMirror({
	GET_QUESTION_EXPLANATION: null,
	GET_QUESTION_EXPLANATION_SUCCESS: null,

	SEND_QUESTION_ANSWER_REQUEST: null,
	SEND_QUESTION_ANSWER_SUCCESS: null,
	SEND_QUESTION_ANSWER_ERROR: null,

	CLEAR_EXPLANATION: null,
	CLEAR_STATE: null,

	SET_EXERCISE_SCORE: null,

	GET_EXERCISE_REQUEST: null,
	GET_EXERCISE_SUCCESS: null,
	GET_EXERCISE_ERROR: null,

	GET_EXERCISE_REPORT_REQUEST: null,
	GET_EXERCISE_REPORT_SUCCESS: null,
	GET_EXERCISE_REPORT_ERROR: null,

	SET_FINISH_EXERCISE: null,

	HANDLE_NEXT_EXERCISE_TYPES: null,

	COUNT_AS_CORRECT: null,
	COUNT_AS_INCORRECT: null,

	TOGGLE_TOPIC_INSTRUCTION: null,
	TOGGLE_QUESTION_INSTRUCTION: null,
	SET_VIEWED_QUESTION_INSTRUCTION: null,

	SEND_TOPIC_INSTRUCTION_FEEDBACK: null,
	SEND_QUESTION_INSTRUCTION_FEEDBACK: null,

	STORE_USER_QUESTION_TIME: null,

	SET_SELECTED_ITEMS: null,
	SET_CURRENT_QUESTION: null,
	TOGGLE_INSTRUCTION_AUDIO: null,

	SAVE_AUDIO_PREFERENCES_REQUEST: null,
	SAVE_AUDIO_PREFERENCES_SUCCESS: null,
	SAVE_AUDIO_PREFERENCES_ERROR: null,

	SET_VIEW_MODE: null,
	SET_READING_PROGRESS: null,
	SET_READING_TEXT_SIZE: null,
	SET_SHOW_INSTRUCTION_HINT: null,
	SET_INSTRUCTION_AUDIO_ON: null,

	TOGGLE_READING_MODE: null,
	TOGGLE_READING_TITLE: null,
	TOGGLE_PULSATING_HIGHLIGHT: null,

	SET_CORRECT_STREAK: null,
	SET_ENDSCREEN: null,
	SET_LEVEL_UP: null,

	FINISH_LEARNING_PATH_SECTION_REQUEST: null,
	FINISH_LEARNING_PATH_SECTION_SUCCESS: null,
	FINISH_LEARNING_PATH_SECTION_ERROR: null,

	SET_MISTAKE_INTERVENTION: null,

	SEND_MISTAKE_INTERVENTION_REQUEST: null,
	SEND_MISTAKE_INTERVENTION_SUCCESS: null,
	SEND_MISTAKE_INTERVENTION_ERROR: null,
});

export const DEFAULT_SPEECH_LOCALE = 'nl';

export const MAX_ANSWER_TIME_IN_SECONDS = 300;

export const MAX_READING_TEXT_SIZE = 3;

export const MIN_QUESTION_LENGTH_FOR_FAST_ANSWERING = 120;

export const FAST_ANSWERING_TIME_IN_MILLISECONDS = 2000;

export const DESKTOP_READING_HEADER_HEIGHT = 72;

export const MOBILE_READING_HEADER_HEIGHT = 48;

export const STOP_AUDIO_PLAYING_EVENT = 'stopAudioPlaying';

export const DELAY_FOR_INSTRUCTION_HINT_IN_SECONDS = 20;

export const WRONG_QUESTIONS_FOR_SECOND_CHANCE = 3;

export const ACTIVE_CLASS = 'active';

export const VISIBLE_CLASS = 'visible';

export const TRUNCATE_CLASS = 'truncated-text';

export const MIN_VISIBLE_CORRECT_STREAK = 5;

export const ENDSCREEN_TYPES = {
	FULL_SCORE: 'full_score',
	LEVEL_UP: 'level_up',
};

import keyMirror from 'keymirror';

export const signInActionTypes = keyMirror({
	LOGIN_REQUEST: null,
	LOGIN_SUCCESS: null,
	LOGIN_ERROR: null,

	SAVE_ACCESS_TOKEN: null,
});

export const SIGN_IN_FORM = 'signInForm';

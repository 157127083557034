import { learningPathActionTypes } from '@/pages/learningPath/Constants';
import { updateTileProgressStatus } from '@/pages/learningPath/Helper';

const initialState = {
	loading: true,
	learningPathUpdated: false,
	steps: [],
	newSteps: [],
	subcategories: [],
	finishTileAnimation: false,
	skipTileAnimation: false,
	shorterLearningPathAnimation: false,
	rewardUserSheetId: null,
	tileSelectionMode: false,
	selectedTile: false,
	previewMode: false,
	frequencyText: null,
	sessionLengthText: null,
	tilesToReplace: [],
};

export default function learningPathReducer(state = initialState, action) {
	const { type, payload } = action;

	const {
		data,
		isUpdated,
		stepId,
		isCorrect,
		finishTileAnimation,
		tileSelectionMode,
		rewardUserSheetId,
		shorterLearningPathAnimation,
		skipTileAnimation,
		selectedTile,
		newSteps,
		steps,
		previewMode,
		tilesToReplace,
		learningPath,
	} = payload || {};

	switch (type) {
		case learningPathActionTypes.GET_LEARNING_PATH_REQUEST:
			return {
				...state,
				loading: true,
			};

		case learningPathActionTypes.GET_LEARNING_PATH_SUCCESS:
			return {
				...state,
				steps: data.learning_path,
				subcategories: data.subcategories,
				frequencyText: data.week_goal_text,
				sessionLengthText: data.session_length_text,
				loading: false,
				learningPathUpdated: false,
			};

		case learningPathActionTypes.GET_LEARNING_PATH_ERROR:
			return {
				...state,
				loading: false,
				learningPathUpdated: true,
			};

		case learningPathActionTypes.UPDATE_LEARNING_PATH:
			return {
				...state,
				steps: [...learningPath],
			};

		case learningPathActionTypes.UPDATE_TILE_PROGRESS_STATUS:
			return {
				...state,
				steps: updateTileProgressStatus(stepId, isCorrect, state.steps),
			};

		case learningPathActionTypes.SET_LEARNING_PATH_UPDATED:
			return {
				...state,
				learningPathUpdated: isUpdated,
			};

		case learningPathActionTypes.SET_FINISH_TILE_ANIMATION:
			return {
				...state,
				finishTileAnimation,
			};

		case learningPathActionTypes.SET_REWARD_USER_SHEET_ID:
			return {
				...state,
				rewardUserSheetId,
			};

		case learningPathActionTypes.SET_TILE_SELECTION_MODE:
			return {
				...state,
				tileSelectionMode,
			};

		case learningPathActionTypes.SET_SHORTER_LEARNING_PATH_ANIMATION:
			return {
				...state,
				shorterLearningPathAnimation,
			};

		case learningPathActionTypes.SET_SKIP_TILE_ANIMATION:
			return {
				...state,
				skipTileAnimation,
			};

		case learningPathActionTypes.SET_SELECTED_TILE:
			return {
				...state,
				selectedTile,
			};

		case learningPathActionTypes.SET_CURRENT_STEPS:
			return {
				...state,
				steps,
			};

		case learningPathActionTypes.SET_NEW_STEPS:
			return {
				...state,
				newSteps,
			};

		case learningPathActionTypes.SET_TILES_TO_REPLACE:
			return {
				...state,
				tilesToReplace,
			};

		case learningPathActionTypes.SET_PREVIEW_MODE:
			return {
				...state,
				previewMode,
			};

		case learningPathActionTypes.CLEAR_LEARNING_PATH:
			return {
				...initialState,
				learningPathUpdated: true,
			};

		case learningPathActionTypes.RECEIVE_LEARNING_PATH_CHANNEL_STREAM:
			return {
				...state,
				loading: true,
			};

		case learningPathActionTypes.RECEIVED_LEARNING_PATH_CHANNEL_STREAM_SUCCESS:
			return {
				...state,
				steps: data.learning_path,
				subcategories: data.subcategories,
				frequencyText: data.week_goal_text,
				sessionLengthText: data.session_length_text,
				loading: false,
				learningPathUpdated: false,
			};

		default:
			return state;
	}
}

import keyMirror from 'keymirror';

export const progressActionTypes = keyMirror({
	GET_PROGRESS_OVERVIEW_REQUEST: null,
	GET_PROGRESS_OVERVIEW_SUCCESS: null,
	GET_PROGRESS_OVERVIEW_ERROR: null,

	GET_PROGRESS_DETAILS_REQUEST: null,
	GET_PROGRESS_DETAILS_SUCCESS: null,
	GET_PROGRESS_DETAILS_ERROR: null,

	TOGGLE_INFO_MODAL: null,
	TOGGLE_PROGRESS_ONBOARDING_MODAL: null,
	CLEAR_PROGRESS_DETAILS: null,
});

export const TOPIC_LEVEL_STATUS = {
	GOOD: 'goed',
	NEED_PRACTICE: 'blijf oefenen',
	EXCELLENT: 'uitstekend',
	REPEAT: 'herhaling',
};

export const TOPIC_LEVEL_STATUS_MAPPING = {
	[TOPIC_LEVEL_STATUS.GOOD]: { class: 'blue', icon: 'icon-thumbs-up' },
	[TOPIC_LEVEL_STATUS.NEED_PRACTICE]: { class: 'orange', icon: 'icon-clock-plus' },
	[TOPIC_LEVEL_STATUS.REPEAT]: { class: 'blue repeat', icon: 'icon-thumbs-up' },
	[TOPIC_LEVEL_STATUS.EXCELLENT]: { class: 'green', icon: 'icon-crown' },
};

export const PROGRESS_INFO_TYPE = {
	DEVELOPMENT: 'development',
	MASTERY: 'mastery',
};

export const MONTH_MAP = {
	januari: 'jan',
	februari: 'feb',
	maart: 'mrt',
	april: 'apr',
	mei: 'mei',
	juni: 'jun',
	juli: 'jul',
	augustus: 'aug',
	september: 'sep',
	oktober: 'okt',
	november: 'nov',
	december: 'dec',
};

export const GRAPH_LEVELS_NUMBER = 12;

export const GRAPH_GRADE_MOBILE_HEIGHT = 96;

export const GRAPH_GRADE_DESKTOP_HEIGHT = 112;

export const GRAPH_LEVEL_HEIGHT = 40;

export const GRAPH_MONTH_MOBILE_WIDTH = 50;

export const GRAPH_MONTH_DESKTOP_WIDTH = 130;

export const GRAPH_SIDE_MOBILE_PADDING = 20;

export const GRAPH_SIDE_DESKTOP_PADDING = 40;

export const GRAPH_GRID_MOBILE_PADDING = 8;

export const GRAPH_GRID_DESKTOP_PADDING = 16;

export const PROGRESS_ONBOARDING_MODAL_SHOWN = 'progressOnboardingModalShown';

export const PROGRESS_ONBOARDING_TOOLTIP_SHOWN = 'progressOnboardingTooltipShown';

import { processRequest } from '../../../../services/Api';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addWysiwygImageModalActionTypes } from './Constants';
import * as addImageActions from './Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			addWysiwygImageModalActionTypes.UPLOAD_WYSIWYG_IMAGE_REQUEST,
			handleUploadImageRequest,
		),
	]);
}

export function* handleUploadImageRequest(action) {
	try {
		const { data: formData, url, quill } = action.payload;

		const { data } = yield call(processRequest, url, 'GET', {content_type: formData.file.type});
		const presignedUrl = data.signed_url;

		yield call(processRequest, presignedUrl, 'PUT', formData.file, true);

		const linkToFile = data.url || presignedUrl.split('?')[0];

		const val = {
			text: formData.caption,
			url: linkToFile,
		};

		const cursorPosition = quill.selection.savedRange.index;
		quill.insertEmbed(cursorPosition, 'figure', val, 'user');
		quill.setSelection(cursorPosition + 2);
		yield put(addImageActions.uploadImageSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(addImageActions.uploadImageError());
	}
}

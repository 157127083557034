import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { sharedTypes } from '@/shared/Constants';
import { processRequest } from '@/services/Api';
import * as errorHandlerActions from '@/components/errorHandler/Actions';
import {
	FRONTEND_ROUTES,
	IS_ADMIN,
	IS_LOCAL_ENVIRONMENT,
	LOGOUT_REDIRECT_URL,
	MARKETING_URL,
} from '@/services/Constants';
import { identifyHotjar } from '@/services/Helper';
import { historyReplace } from '@/services/History';
import { getUserId } from '@/components/onboarding/Selectors';
import { deleteExperiment, handleExperiment } from '@/shared/Helpers';
import { removeAccessToken } from '@/services/AccessToken';
import * as sharedActions from '@/shared/Actions';
import * as dailyReportActions from '@/components/dailyReport/Actions';
import * as learningPathActions from '@/pages/learningPath/Actions';

export default function* () {
	yield all([
		yield takeLatest(sharedTypes.GET_USER_DATA_REQUEST, handleGetUserDataRequest),
		yield takeLatest(sharedTypes.LOGOUT, handleUserLogout),
		yield takeLatest(sharedTypes.UPDATE_USER_DATA_REQUEST, handleUpdateUserDataRequest),
		yield takeLatest(sharedTypes.SEND_EVENT_REQUEST, handleSendEventRequest),
		yield takeLatest(sharedTypes.HANDLE_USER_EXPERIMENT, handleUserExperimentRequest),
	]);
}

export function* handleGetUserDataRequest({ payload }) {
	try {
		const { options } = payload || {};

		const { data: userData } = yield call(processRequest, 'get_user_data');

		const { needs_to_fill_grade, child_name, grade_id, sidk_user, sidk_closed } =
			userData || {};

		if (sidk_user && sidk_closed) {
			yield put(sharedActions.logout());
			window.location.href = `${MARKETING_URL}school-gesloten`;
			return;
		}

		yield put(sharedActions.getUserDataSuccess(userData));

		if (IS_ADMIN) return;

		identifyHotjar(userData);

		if (options?.skipOnboardingCheck) return;

		if (!grade_id) {
			yield call(historyReplace, FRONTEND_ROUTES.ONBOARDING_CHOOSE_GRADE);
			return;
		}

		if (needs_to_fill_grade) {
			yield call(historyReplace, FRONTEND_ROUTES.NEW_SCHOOL_YEAR);
		}

		if (!child_name) {
			yield call(historyReplace, FRONTEND_ROUTES.SAVE_CHILD_NAME);
			return;
		}

		const { data: dailyReport } = yield call(processRequest, 'users/daily_report');
		yield put(dailyReportActions.getDailyReportSuccess(dailyReport));

		identifyHotjar(userData, dailyReport);

		yield put(learningPathActions.getLearningPathRequest());
	} catch (e) {
		yield put(sharedActions.getUserDataError());
		yield put(errorHandlerActions.handleError(e, 'page-blocking'));
	}
}

export function* handleUserLogout() {
	try {
		if (IS_ADMIN || IS_LOCAL_ENVIRONMENT) {
			yield call(processRequest, 'auth/invalidate_token', 'POST');
			yield call(removeAccessToken);
			window.location.href = FRONTEND_ROUTES.LOGIN;
		} else {
			window.location.href = LOGOUT_REDIRECT_URL;
		}
	} catch (e) {
		yield put(sharedActions.getUserDataError());
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleUpdateUserDataRequest({ payload }) {
	try {
		const userId = yield select(getUserId);
		const { data } = payload;

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: data,
		});

		yield put(sharedActions.getUserDataRequest());

		yield put(sharedActions.updateUserDataSuccess());
	} catch (e) {
		yield put(sharedActions.updateUserDataError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleSendEventRequest({ payload }) {
	try {
		const { data: requestData } = payload;
		if (IS_LOCAL_ENVIRONMENT) {
			console.log('send_event', requestData);
		} else {
			yield call(processRequest, `send_event`, 'POST', requestData);
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield put(sharedActions.sendEventError());
	}
}

export function* handleUserExperimentRequest({ payload }) {
	const { experimentName } = payload;

	try {
		const { data } = yield call(processRequest, `public/experiments/${experimentName}`);
		if (data.experiment) {
			yield call(handleExperiment, data.experiment);
		}
		yield put(sharedActions.handleUserExperimentSuccess(experimentName));
	} catch (e) {
		yield call(deleteExperiment, experimentName);
		yield put(sharedActions.handleUserExperimentSuccess(experimentName));
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

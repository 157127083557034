import { COLORS, COLORS_MAP, TILE_STATUSES } from '@/pages/learningPath/Constants';
import i18n from 'i18next';
import confetti from 'canvas-confetti';

export const updateTileProgressStatus = (stepId, isCorrect, stateSteps) => {
	if (stateSteps.length > 0) {
		let steps = stateSteps.map((step) => ({ ...step }));
		const stepIndex = steps.findIndex((step) => step.id === parseInt(stepId));

		if (stepIndex > -1) {
			if (isCorrect) {
				steps[stepIndex].correct_answers_count += 1;
			} else {
				steps[stepIndex].wrong_answers_count += 1;
			}

			const progress = Math.round(
				((steps[stepIndex].correct_answers_count + steps[stepIndex].wrong_answers_count) /
					steps[stepIndex].total_questions_count) *
					100,
			);
			const status =
				progress === 100 ? TILE_STATUSES.TILE_COMPLETED : TILE_STATUSES.TILE_IN_PROGRESS;

			steps[stepIndex].status = status;
			steps[stepIndex].progress = progress;

			if (status === TILE_STATUSES.TILE_IN_PROGRESS) {
				steps = steps.map((step, index) => {
					if (
						index < stepIndex &&
						[TILE_STATUSES.TILE_IN_PROGRESS, TILE_STATUSES.TILE_LOCKED].includes(
							step.status,
						)
					) {
						step.status = TILE_STATUSES.TILE_COMPLETED;
					}
					return step;
				});
			}

			if (status === TILE_STATUSES.TILE_COMPLETED) {
				if (steps[stepIndex + 1]?.status === TILE_STATUSES.TILE_LOCKED) {
					if (steps[stepIndex + 1].is_daily_goal_flag) {
						steps[stepIndex + 1].status = TILE_STATUSES.TILE_COMPLETED;
						if (steps[stepIndex + 2]?.status === TILE_STATUSES.TILE_LOCKED) {
							steps[stepIndex + 2].status = TILE_STATUSES.TILE_IN_PROGRESS;
						}
					} else {
						steps[stepIndex + 1].status = TILE_STATUSES.TILE_IN_PROGRESS;
					}
				}
			}
		}

		return steps;
	}
	return stateSteps;
};

export const showFireworksConfetti = (id, duration = 3000) => {
	const animationEnd = Date.now() + duration;
	const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: -1 };
	const colors = Object.values(COLORS_MAP);
	let confettiTool = confetti;

	if (id) {
		const canvas = document.getElementById(id);
		confettiTool = canvas?.confetti || confettiTool.create(canvas, { resize: true });
	}

	const randomInRange = (min, max) => {
		return Math.random() * (max - min) + min;
	};

	const interval = setInterval(() => {
		const timeLeft = animationEnd - Date.now();

		if (timeLeft <= 0) {
			return clearInterval(interval);
		}

		const particleCount = 50 * (timeLeft / duration);
		confettiTool({
			...defaults,
			particleCount,
			colors,
			origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
		});
		confettiTool({
			...defaults,
			particleCount,
			colors,
			origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
		});
	}, 250);
};

export const checkDailyGoal = (sectionId, steps) => {
	if (!steps?.length || !sectionId) return false;

	const currentStepIndex = steps.findIndex(({ id }) => id === parseInt(sectionId));
	const currentStep = steps[currentStepIndex];
	const nextStep = steps[currentStepIndex + 1];

	return currentStep?.is_daily_goal || (nextStep?.is_skipped_tile && nextStep?.is_daily_goal);
};

export const isEmptyLearningPath = (learningPath) => {
	return !learningPath?.length || learningPath.length === 1;
};

export const addDailyGoalFlag = (steps) => {
	let isDailyGoalTileAdded = false;
	return steps.reduce((acc, step, index) => {
		if (!isDailyGoalTileAdded) {
			step.is_part_of_daily_goal = true;
		}
		acc.push(step);
		if (step.is_daily_goal) {
			isDailyGoalTileAdded = true;
			let dailyGoalTileStatus = TILE_STATUSES.TILE_LOCKED;
			if (step.is_skipped_tile) {
				dailyGoalTileStatus =
					steps[index - 1]?.status === TILE_STATUSES.TILE_COMPLETED
						? TILE_STATUSES.TILE_COMPLETED
						: TILE_STATUSES.TILE_LOCKED;
			} else if (step.status === TILE_STATUSES.TILE_COMPLETED) {
				dailyGoalTileStatus = TILE_STATUSES.TILE_COMPLETED;
			}

			acc.push({
				id: `${step.id}-daily-goal`,
				status: dailyGoalTileStatus,
				color: step.color,
				is_daily_goal_flag: true,
			});
		}
		return acc;
	}, []);
};

export const prefillOnboardingData = (learningPath) => {
	return learningPath.map((step) => {
		const {
			is_onboarding_tile,
			is_new_difficulty_onboarding_tile,
			is_sticker_onboarding_tile,
		} = step;

		if (is_onboarding_tile) {
			return {
				...step,
				id: 'onboarding_tile',
				title: i18n.t('learning_path.onboarding_title'),
				image: '🤩',
				color: COLORS.ORANGE,
			};
		}

		if (is_new_difficulty_onboarding_tile) {
			return {
				...step,
				id: 'new_difficulty_onboarding_tile',
				title: i18n.t('learning_path.new_difficulty_onboarding_title'),
				image: '🌱',
				color: COLORS.TURQUOISE,
			};
		}

		if (is_sticker_onboarding_tile) {
			return {
				...step,
				id: 'sticker_onboarding_tile',
			};
		}

		return step;
	});
};

export const addTileLinesData = (steps) => {
	const skipTileIndex = steps.findIndex((stepItem) => stepItem.is_skipped_tile);
	if (skipTileIndex >= 0) {
		const beforeSkipTileIndex = skipTileIndex - 1;
		steps[beforeSkipTileIndex].has_skip_line = true;
	}

	const mistakeTileIndex = steps.findIndex((stepItem) => stepItem.is_mistake_intervention_tile);
	if (mistakeTileIndex >= 0) {
		const beforeMistakeTileIndex = mistakeTileIndex - 1;
		steps[beforeMistakeTileIndex].has_mistake_line = true;
	}

	return steps;
};

export const updateTileLinesColor = (steps) => {
	return steps.map((currentStep, index) => {
		const nextStep = steps[index + 1];

		const currentStepColor =
			currentStep.status === TILE_STATUSES.TILE_LOCKED
				? COLORS.GREY
				: currentStep?.color ?? COLORS.GREY;

		const nextStepColor =
			!nextStep || nextStep.status === TILE_STATUSES.TILE_LOCKED
				? COLORS.GREY
				: nextStep.color ?? COLORS.GREY;

		return {
			...currentStep,
			line_colors: [currentStepColor, nextStepColor],
		};
	});
};

export const updateTilePositionIndex = (steps) => {
	return steps.map((step, index) => {
		return {
			...step,
			position_index: index % 8,
		};
	});
};

export const updateLearningPath = (learningPath) => {
	learningPath = addDailyGoalFlag(learningPath);
	learningPath = prefillOnboardingData(learningPath);
	learningPath = addTileLinesData(learningPath);
	learningPath = updateTileLinesColor(learningPath);
	learningPath = updateTilePositionIndex(learningPath);
	return learningPath;
};

import { profileTypes } from './Constants';
import { setAccessToken } from '../../../services/AccessToken';

const initialState = {
	loading: false,
	loadingPersonalData: false,
	isChangeEmailModal: false,
	isChangePasswordModal: false,
};

export default function profileReducer(state = initialState, action) {
	const { type, payload } = action;
	const { status, token } = payload || {};

	switch (type) {
		case profileTypes.TOGGLE_CHANGE_PASSWORD_MODAL:
			return {
				...state,
				isChangePasswordModal: status,
			};

		case profileTypes.SET_NEW_PASSWORD:
			return {
				...state,
				loading: true,
			};

		case profileTypes.SET_NEW_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				isChangePasswordModal: false,
			};

		case profileTypes.SET_NEW_PASSWORD_ERROR:
			return {
				...state,
				loading: false,
			};

		case profileTypes.TOGGLE_CHANGE_EMAIL_MODAL:
			return {
				...state,
				isChangeEmailModal: status,
			};

		case profileTypes.SET_NEW_EMAIL:
			return {
				...state,
				loading: true,
			};

		case profileTypes.SET_NEW_EMAIL_SUCCESS:
			setAccessToken(token);
			return {
				...state,
				loading: false,
				isChangeEmailModal: false,
			};

		case profileTypes.SET_NEW_EMAIL_ERROR:
			return {
				...state,
				loading: false,
			};

		case profileTypes.SET_PERSONAL_DATA_REQUEST:
			return {
				...state,
				loadingPersonalData: true,
			};

		case profileTypes.SET_PERSONAL_DATA_SUCCESS:
		case profileTypes.SET_PERSONAL_DATA_ERROR:
			return {
				...state,
				loadingPersonalData: false,
			};

		default:
			return state;
	}
}

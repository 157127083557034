import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { createBrowserHistory, createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSagaCreator from '@/combineSaga';
import rootReducerCreator from '@/combineReducer';
import { BASENAME, IS_MOBILE_APP } from '@/services/Constants';

let history = createBrowserHistory({ basename: BASENAME ? `/${BASENAME}` : '' });

if (IS_MOBILE_APP) {
	history = createHashHistory();
}

const middlewares = [];
const sagaMiddleware = createSagaMiddleware();

if (import.meta.env.MODE !== 'production') {
	const logger = createLogger({
		level: 'info',
		collapsed: true,
	});

	middlewares.push(logger);
}

const composeEnhancers =
	import.meta.env.MODE !== 'production' &&
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

const enhancers = composeEnhancers(
	applyMiddleware(sagaMiddleware, routerMiddleware(history), ...middlewares),
);

const store = createStore(rootReducerCreator(history), enhancers);
sagaMiddleware.run(rootSagaCreator);
export { store, history };

import keyMirror from 'keymirror';
import _ from 'lodash';

export const profileTypes = keyMirror({
	SET_PERSONAL_DATA_REQUEST: null,
	SET_PERSONAL_DATA_SUCCESS: null,
	SET_PERSONAL_DATA_ERROR: null,

	TOGGLE_CHANGE_PASSWORD_MODAL: null,
	SET_NEW_PASSWORD: null,
	SET_NEW_PASSWORD_SUCCESS: null,
	SET_NEW_PASSWORD_ERROR: null,

	TOGGLE_CHANGE_EMAIL_MODAL: null,
	CHECK_EMAIL: null,
	SET_NEW_EMAIL: null,
	SET_NEW_EMAIL_SUCCESS: null,
	SET_NEW_EMAIL_ERROR: null,
});

export const PERSONAL_INFO_FORM = 'personalInfoForm';

export const CHANGE_EMAIL_FORM = 'changeEmailForm';

export const CHANGE_PASSWORD_FORM = 'changePasswordForm';

export const groupBySections = (items) => {
	return (
		!!items.length &&
		_(items)
			.groupBy((prop) => prop.channel)
			.value()
	);
};
